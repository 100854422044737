import React from "react";
import styled from "styled-components";
import { Font, Color, rem } from "../../utils/style";
import MagicLink from "../MagicLink";
import $ from "jquery";

const Banner = styled.div`
  ${Font.circular}
  background-color: ${Color.white};
  font-size: ${rem(18)};

  width: 100%;
  height: 52px;

  position: fixed;
  top: ${p => p.messageBannerHeight};
  left: 0;
  z-index: 999;

  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 0 15px;

  display: none;
  &.compacted {
    display: flex;
  }
`;

const ProductTitle = styled.h1`
  ${Font.circular}
  background-color: ${Color.white};
  font-size: ${rem(18)};
  display: flex;
  margin: 0;

  > p {
    margin: 0;
  }
  em {
    ${Font.dutch}
    font-style: italic;
  }
`;

export default class MobileBannerTop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrollingDown: true,
      messageBannerPresent: false,
      messageBannerHeight: props.offsetFromBanner || 0,
    };
    this.lastScrollTop = 0;
  }

  componentDidMount() {
    $(window).on("scroll.banner", this.setIsScrollingDown.bind(this));

    $(window).on("messageBanner.open", (context, height) => {
      this.setState({
        messageBannerPresent: true,
        messageBannerHeight: height,
      });
    });

    $(window).on("messageBanner.close", () => {
      this.setState({
        messageBannerPresent: false,
        messageBannerHeight: 0,
      });
    });
  }

  componentWillUnmount() {
    $(window).off("scroll.banner");
  }

  setIsScrollingDown() {
    let st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

    this.setState({
      isScrollingDown: st > this.lastScrollTop,
    });
    this.lastScrollTop = st <= 0 ? 0 : st;
  }

  render() {
    const { bannerText, yieldedContent, id } = this.props;

    const { isScrollingDown, messageBannerHeight } = this.state;
    const linkNode = yieldedContent ? yieldedContent.props.to : null;

    return (
      <Banner
        id={id}
        className={isScrollingDown ? "compacted" : ""}
        messageBannerHeight={messageBannerHeight}
      >
        {linkNode ? (
          <MagicLink id={`${id}_link`} to={linkNode}>
            <ProductTitle dangerouslySetInnerHTML={{ __html: bannerText }} />
          </MagicLink>
        ) : (
          <ProductTitle
            id={`${id}_title`}
            dangerouslySetInnerHTML={{ __html: bannerText }}
          />
        )}
        {yieldedContent}
      </Banner>
    );
  }
}
