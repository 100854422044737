import React from "react";
import styled from "styled-components";
import Container from "../Container";
import {
  responsive,
  media,
  rem,
  Color,
  Font,
  capitalize,
} from "../../utils/style";
import { BenefitsIcons } from "../../utils/svg";
import Text from "../Text";

const BenefitsContainer = styled(Container)`
  margin-top: 80px;

  ${responsive.md`
    margin-top: 120px;

  `}
`;

const BenefitsDiv = styled.div.attrs({})`
  background-color: #f1f5f7;
  padding: ${rem(40)} ${rem(56)} !important;
  margin-bottom: 30px;

  ${media.notDesktop`
    padding: ${rem(40)} ${rem(24)} !important;
    margin-bottom: 0;
  `};
`;

const BenefitsHeader = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  ${Font.circular} font-weight: 500;
  letter-spacing: 0;
  line-height: ${rem(28)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding-bottom: 8px;
  margin-bottom: ${rem(26)};
`;

const BenefitItem = styled.div.attrs({
  className: "d-flex align-items-center",
})`
  margin-bottom: ${rem(26)};
  ${Font.circular}
  font-size: ${rem(16)};
  font-weight: 300;
  display: flex;

  &:last-child {
    margin: 0;
  }

  p {
    margin: 0;
    margin-left: ${rem(8)};
  }
`;

const BenefitIcon = styled.span`
  width: ${rem(28)};
  height: ${rem(28)};
  margin-right: ${rem(12)};
  vertical-align: text-bottom;
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const DescriptionWrapper = styled.div.attrs({
  className:
    "col-12 col-sm-6 col-md-5 offset-0 offset-sm-1 offset-md-2 d-flex align-items-center",
})``;

const Description = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  ${Font.circular} font-weight: 500;
  text-align: left;
  letter-spacing: -0.2px;
  line-height: ${rem(32)};
  position: relative;
  padding-top: ${rem(34)};

  ${media.mobile`
    margin-top: ${rem(80)};
  `} &:before {
    content: "";
    width: ${rem(32)};
    height: ${rem(4)};
    background-color: ${Color.ritualBlue};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

/* eslint-disable react/prefer-stateless-function, react/prop-types */
export default class Benefits extends React.Component {
  render() {
    const { description, icons } = this.props.ingredient;
    const iconData = JSON.parse(icons.childMarkdownRemark.rawMarkdownBody);
    return (
      <BenefitsContainer>
        <div className="row">
          <div className="flush-left col-12 col-sm-5 col-md-4 offset-0">
            <BenefitsDiv>
              <BenefitsHeader>
                <Text
                  id="ingredients.benefits.title"
                  defaultMessage="Benefits"
                />
              </BenefitsHeader>
              {iconData.map((icon, i) => {
                // TODO: Change svg url in Contentful
                let iconName = icon.url
                  .replace(/\/svg\/icon-/gi, "")
                  .replace(/.svg/gi, "")
                  .replace(/-/gi, "");
                let BenefitsIconElement = BenefitsIcons[capitalize(iconName)];
                return (
                  <BenefitItem key={`icon-${i}`}>
                    <BenefitIcon>
                      <BenefitsIconElement />
                    </BenefitIcon>
                    <p>{icon.name}</p>
                  </BenefitItem>
                );
              })}
            </BenefitsDiv>
          </div>

          <DescriptionWrapper>
            <Description>{description}</Description>
          </DescriptionWrapper>
        </div>
      </BenefitsContainer>
    );
  }
}
