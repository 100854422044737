import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

import ProductCtaBar from "../product/ProductCtaBar";
import MagicLink from "../MagicLink";
import Text from "../Text";

import { rem, Font, Color } from "../../utils/style";
import metrics from "../../utils/metrics";

// Services
import intl from "../../services/intl";

const ProductLink = styled(MagicLink)`
  ${Font.circular};
  font-size: ${rem(14)} !important;
  line-height: ${rem(24)} !important;
  border-bottom: 2px solid ${Color.ritualBlue};
`;

const componentQuery = graphql`
  query stickyProductNavQuery {
    allContentfulProduct {
      nodes {
        node_locale
        __typename
        name {
          name
        }
        sku
        slug
        price
        socialImage {
          file {
            url
          }
        }
        stockStatus
      }
    }
  }
`;

class StickyProductNav extends React.Component {
  constructor(props) {
    super(props);
    this.contentfulProducts = intl.cf(props.data.allContentfulProduct.nodes);
  }

  trackProductCTAClick(title, location, e) {
    let event = {
      title: title,
      nonInteraction: false,
      location: location,
    };
    metrics.track("CTA Clicked", event);
  }

  render() {
    const productSku = this.props.sku;

    const {
      ctaLocation,
      scrollOffset,
      showLearnMore,
      showTopBar,
      offsetFromBanner,
    } = this.props;

    const contentfulProduct = this.contentfulProducts.find(product => {
      return productSku === product.sku;
    });

    const ctaText = intl.t("general.button-add-cart", "Add to Cart");

    return (
      <ProductCtaBar
        onClick={this.trackProductCTAClick.bind(this, ctaText, ctaLocation)}
        mainText={intl.t("product.hero.button-price", "{amount} per month", {
          amount: intl.formatCurrency(contentfulProduct.price, { round: true }),
        })}
        buttonCta={ctaText}
        product={contentfulProduct}
        productName={contentfulProduct.name}
        hasActiveLink={showLearnMore}
        showTopBar={showTopBar}
        offsetFromBanner={offsetFromBanner}
        scrollOffset={scrollOffset}
      >
        {showLearnMore && (
          <ProductLink to={contentfulProduct} className="-underline">
            <Text id="general.learn-more" defaultMessage="Learn More" />
          </ProductLink>
        )}
      </ProductCtaBar>
    );
  }
}

export default function(props) {
  return (
    <StaticQuery
      query={componentQuery}
      render={data => <StickyProductNav {...props} data={data} />}
    />
  );
}
