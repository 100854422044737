import React, { useEffect, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import throttle from "lodash/throttle";

// Utils
import {
  Font,
  Color,
  rem,
  media,
  atLeast,
  responsive,
} from "../../utils/style";
import NameMap from "../../utils/nameMap";

// Components
import Container from "../Container";
import Row from "../Row";
import CheckoutButton from "./CheckoutButton";
import MobileBannerBottom from "./MobileBannerBottom";
import MobileBannerTop from "./MobileBannerTop";

// Styled Elements
const KustomerIcon = createGlobalStyle`
  ${p => {
    if (p.raised) {
      return `
        .kustomer-app-icon {
          bottom: 75px !important;
          margin-bottom: env(safe-area-inset-bottom) !important;
        }
      `;
    }
  }}
`;

const BarWrapper = styled.div.attrs({
  id: "product-cta-bar-desktop",
  className: "product-banner",
})`
  position: fixed;
  top: 64px;
  right: 0;
  left: 0;
  z-index: 106;
  background-color: ${Color.white};
  border-bottom: 1px solid #ccc;
  transform: translateY(-200%);
  transition: transform 320ms ease-in-out, opacity 200ms ease-out;
  opacity: 0;

  &.active {
    top: initial;

    ${responsive.sm`
      transform: translateY(0);
      top: ${p => {
        const headerHeight = 52;
        return p.offsetFromBanner
          ? p.offsetFromBanner + headerHeight
          : headerHeight;
      }}px;
      opacity: 1;
    `}

    ${responsive.md`
      top: ${p => (p.offsetFromBanner ? p.offsetFromBanner + 64 : 64)}px;
    `}
  }

  ${media.mobile`
    top: initial;
    bottom: 0;
    transform: translateY(100%);
    border-top: 1px solid #ccc;
    border-bottom: 0;
  `};
`;

const BarContainer = styled(Container).attrs({
  id: "product-cta-bar-desktop_container",
})`
  ${media.tablet`
    padding-left: max(15px, env(safe-area-inset-left));
    padding-right: max(15px, env(safe-area-inset-right));
  `} a {
    width: ${p => (p.linkWidth ? p.linkWidth + "px" : "auto")};
  }

  ${media.mobile`
    padding-bottom: env(safe-area-inset-bottom);

    a {
      width: 100% !important;
      margin: 0 auto;
    }
  `};
`;

const Column = styled.div.attrs({
  className: "col-12",
})`
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  ${media.tablet`
    width: 100%;
  `};
`;

const ProductName = styled.h2.attrs({
  id: "product-cta-bar-desktop_container_column_product-name",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  font-weight: 500;
  text-align: left;
  letter-spacing: -0.2px;
  margin: 0;

  em {
    ${Font.dutch}
    font-style: italic;
  }

  ${media.tablet`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 0;
  `}

  ${media.mobile`
    display: none !important;
  `}
`;

const YieldedLinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  &.active {
    display: none;
  }

  & > * {
    margin: 0;
  }
`;

const ProductLinks = styled.div.attrs({
  id: "product-cta-bar-desktop_container_column_links",
})`
  display: flex;
`;

const MobileBannerContainer = styled.div`
  width: 100%;
  z-index: 999;
  opacity: 0;
  visibility: hidden;

  &.active {
    opacity: 1;
    visibility: visible;
  }
`;

export default function ProductCtaBar({
  buttonCta,
  mainText,
  productName,
  hasActiveLink,
  onClick,
  linkWidth,
  offsetFromBanner,
  children,
  product,
  showTopBar = true,
  scrollOffset = 700,
}) {
  const [showProductCtaBar, setShowProductCtaBar] = useState(false);
  const [atLeastTablet, setAtLeastTablet] = useState(false);
  const productNameHtml = NameMap(productName).html;

  useEffect(() => {
    setAtLeastTablet(atLeast.tablet());
  }, []);

  useEffect(() => {
    const handleScroll = throttle(() => {
      let header = document.getElementsByTagName("header")[0];
      let footer = document.getElementsByTagName("footer")[0];
      let position = window.pageYOffset;

      let headerHeight = header.offsetHeight;
      let footerHeight = footer.offsetHeight;
      let footerPosition = footer.offsetTop;

      let isBelowHeroAndAboveFooter =
        position >= scrollOffset - headerHeight &&
        position < footerPosition - headerHeight - footerHeight / 2 &&
        !showProductCtaBar;

      let isBelowFooter =
        position >= footerPosition - headerHeight - footerHeight / 2 &&
        showProductCtaBar;

      let isAboveHero =
        position < scrollOffset - headerHeight && showProductCtaBar;

      if (isBelowHeroAndAboveFooter) {
        setShowProductCtaBar(true);
      } else if (isBelowFooter) {
        setShowProductCtaBar(false);
      } else if (isAboveHero) {
        setShowProductCtaBar(false);
      }
    }, 200);

    const handleResize = throttle(
      () => setAtLeastTablet(atLeast.tablet()),
      400,
    );

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [showProductCtaBar]);

  let checkoutButtonProps = {
    buttonLocation: "Sticky Nav",
    buttonText: mainText,
    appendText: buttonCta,
    stockStatus: product.stockStatus,
    trackCTAClick: onClick,
    product,
    className: "ProductCtaButton",
  };

  return atLeastTablet ? (
    <BarWrapper
      className={showProductCtaBar ? "active" : ""}
      offsetFromBanner={offsetFromBanner}
    >
      <BarContainer linkWidth={linkWidth}>
        <Row id="product-cta-bar-desktop_container_row">
          <Column id="product-cta-bar-desktop_container_column">
            <ProductName
              dangerouslySetInnerHTML={{ __html: productNameHtml }}
            />
            <ProductLinks>
              {children && (
                <YieldedLinkContainer>{children}</YieldedLinkContainer>
              )}
              <CheckoutButton {...checkoutButtonProps} />
            </ProductLinks>
          </Column>
        </Row>
      </BarContainer>
    </BarWrapper>
  ) : (
    <MobileBannerContainer
      id="product-cta-bar-mobile"
      className={showProductCtaBar ? "active" : ""}
    >
      <KustomerIcon raised={showProductCtaBar} />
      {showTopBar && (
        <MobileBannerTop
          id="product-cta-bar-mobile_top"
          bannerText={productNameHtml}
          hasActiveLink={hasActiveLink}
          offsetFromBanner={offsetFromBanner}
          linkAnchor="#reviews"
          linkText="Read Reviews"
          linkHref="#reviews"
          yieldedContent={children}
        />
      )}
      <MobileBannerBottom
        id="product-cta-bar-mobile_bottom"
        checkoutButtonProps={checkoutButtonProps}
      />
    </MobileBannerContainer>
  );
}
