import React from "react";
import styled from "styled-components";
import { Color } from "../../utils/style";
import Container from "../Container";
import Row from "../Row";
import CheckoutButton from "./CheckoutButton";

const Wrapper = styled.div.attrs({
  className: "mobile-banner-bottom",
})`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;

  .mobile-banner-bottom-btn-container {
    background-color: ${Color.white};
    border-top: 1px solid #ccc;
    padding-bottom: 12px;
    width: 100%;
    z-index: 399;

    .row {
      align-items: center;
      height: 100%;

      a {
        width: 100%;
      }
    }

    padding-top: 12px;
    padding-bottom: max(env(safe-area-inset-bottom), 12px);
  }
`;

export default class MobileBannerBottom extends React.Component {
  render() {
    const { checkoutButtonProps, id } = this.props;

    return (
      <Wrapper id={id}>
        <Container
          id={`${id}_container`}
          className="mobile-banner-bottom-btn-container"
        >
          <Row className="px-3--5" id={`${id}_container_row`}>
            <CheckoutButton {...checkoutButtonProps} />
          </Row>
        </Container>
      </Wrapper>
    );
  }
}
