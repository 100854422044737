import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import Container from "../Container";
import { responsive, media, rem, Font, Color } from "../../utils/style";

const InteractionsContainer = styled.div`
  margin-top: 80px;

  ${responsive.md`
    margin-top: 120px;
  `}
`;

const Description = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  ${Font.circular} text-align: left;
  font-weight: 500;
  line-height: ${rem(32)};
  position: relative;
  padding-top: ${rem(34)};
  margin: 0;

  ${media.mobile`
    font-size: ${rem(22)};
    margin-top: ${rem(40)};
  `} &:before {
    content: "";
    width: ${rem(32)};
    height: ${rem(4)};
    background-color: ${Color.ritualBlue};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  left: ${rem(10)};
`;

const CircleImage = styled(Img)`
  background-color: #f1f5f7;
`;

const Circle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-left: ${rem(-10)};

  &:before {
    content: "";
    display: block;
    padding-top: 100%; /* ratio of 1:1*/
  }

  &:first-child {
    margin-right: ${rem(10)}
  }

  &:first-child {
    &:nth-last-child(3) { /* Targets first child that is also 3 from the end */
      color: white;
      background-color: ${Color.ritualBlue};
    }
    &:nth-last-child(2) { /* Targets first child that is also 2 from the end */
      color: ${Color.ritualBlue};
      background-color: ${Color.ritualYellow};
    }
  }

  &:nth-child(2) {
    &:not(:last-child) {
      color: ${Color.ritualBlue};
      background-color: ${Color.ritualYellow};
    }
  }

  &:last-child {
    background-color: transparent;
  }

  & > p {
    margin: 0;
    padding: ${rem(8)};
    list-style: none;
    ${Font.circular}
    font-size: ${rem(22)};
    font-weight: 700;
    line-height: ${rem(26)};
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${media.tablet`
      font-size: ${rem(16)};
      line-height: ${rem(20)};
    `}

    ${media.mobile`
      font-size: ${rem(14)};
      line-height: ${rem(18)};
    `}

    @media (min-width: 450px) and (max-width: 749px) {
      font-size: ${rem(20)};
      line-height: ${rem(24)};
    }

    span {
      display: block;
      font-size: ${rem(32)};
      line-height: 1;

      ${media.tablet`
        font-size: ${rem(24)};
      `}

      ${media.mobile`
        font-size: ${rem(16)};
      `}

      @media (min-width: 450px) and (max-width: 749px) {
        font-size: ${rem(24)};
      }
    }
  }
`;

/* eslint-disable react/prefer-stateless-function */
export default class IngredientInteractions extends React.Component {
  render() {
    const { ingredient } = this.props;
    const { interactions, interactionsDescription, image, slug } = ingredient;

    if (!interactions || !interactionsDescription) return null;

    return (
      <Container>
        <InteractionsContainer>
          <div className="row">
            <div className="flush-left col-12 col-sm-6 col-md-5 order-1 order-sm-0 d-flex align-items-center">
              <Description>{interactionsDescription}</Description>
            </div>

            <div className="col-12 col-sm-6 offset-md-1 order-0 order-sm-1">
              <CircleContainer>
                {interactions.map((interaction, i) => {
                  return (
                    <Circle key={slug + "-interaction-" + i}>
                      <p>
                        <span>+</span>
                        {interaction}
                      </p>
                    </Circle>
                  );
                })}
                <Circle>
                  <CircleImage
                    fluid={image.fluid}
                    loading="eager"
                    fadeIn={false}
                    alt={image.title}
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      userSelect: "none",
                      userDrag: "none",
                      pointerEvents: "none",
                      touchCallout: "none",
                    }}
                  />
                </Circle>
              </CircleContainer>
            </div>
          </div>
        </InteractionsContainer>
      </Container>
    );
  }
}
