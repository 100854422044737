import React from "react";
import styled from "styled-components";
import Container from "../Container";
import { media, rem, Color, Font, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import NameMap from "../../utils/nameMap";

import Text from "../Text";

const EvidenceContainer = styled(Container)`
  margin: ${rem(120)} auto;

  ${media.tablet`
    margin: ${rem(80)} auto;
  `};
`;

const Header = styled.h2`
  color: ${Color.ritualBlue};
  ${Font.circular}
  font-size: ${rem(48)};
  text-align: center;
  letter-spacing: -1.4px;
  line-height: ${rem(54)};
  margin-bottom: ${rem(24)};

  ${media.tablet`
    line-height: ${rem(36)};
    font-size: ${rem(30)};
    margin-bottom: ${rem(16)};
    letter-spacing: -0.4px;
  `}

  ${media.mobile`
    line-height: ${rem(36)};
    font-size: ${rem(30)};
    margin-bottom: ${rem(16)};
    letter-spacing: -0.4px;
  `}
`;

const HeaderEmphasis = styled.em`
  ${Font.dutch};
`;

const Blurb = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  ${Font.dutch} font-weight: normal;
  text-align: center;
  line-height: ${rem(28)};

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

const StudyHeader = styled.p`
  color: ${Color.ritualBlue};
  font-size: 22px;
  ${Font.circular} font-weight: 500;
  text-align: center;
  letter-spacing: -0.2px;
  line-height: 32px;

  ${media.mobile`
    margin-top: ${rem(24)};
  `};
`;

const StudyLink = styled.a.attrs({
  className: "-underline",
})``;

const Articles = styled.div`
  margin-top: ${rem(56)};
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;

  ${media.mobile`
    margin-top: ${rem(24)};
  `};
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StudyIconWrapper = styled.div`
  align-self: flex-start;
  padding-top: 5px;
  padding-right: ${rem(16)};
`;

const Article = styled.article`
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: ${rem(24)};
  width: 100%;
  padding-left: 0 !important;

  ${media.mobile`
    padding-right: 0 !important;
  `};
`;

const ArticleRow = styled.div.attrs({
  className: "row",
})`
  width: 100%;
  margin-left: 0 !important;
  ${media.mobile`
    margin-left: 0 !important;
    margin-right: 0 !important;
  `};
`;

const StudyName = styled.h2`
  color: ${Color.ritualBlue};
  ${Font.circular} font-weight: 500;
  text-align: left;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: ${rem(16)};
  margin-right: ${rem(30)};

  ${responsive.sm`
    margin-right: 0;
  `}

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    margin: 0;
  `}
`;

const StudyInstitution = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  ${Font.circular} font-weight: 300;
  text-align: left;
  line-height: ${rem(26)};
  margin: 0;
`;

/* eslint-disable react/prefer-stateless-function, react/prop-types */
export default class EvidenceBased extends React.Component {
  render() {
    const {
      studies,
      studiesLink,
      studyCount,
      studySubject,
    } = this.props.ingredient;
    let studyData = JSON.parse(studies.childMarkdownRemark.rawMarkdownBody);

    return (
      <EvidenceContainer>
        <div className="row align-items-center">
          <div className="col-12 offset-0 col-sm-8 offset-sm-2">
            <Header className="text-center">
              <HeaderEmphasis>
                <Text
                  id="ingredients.evidence.header-emphasis"
                  defaultMessage="Evidence-Based"
                />
              </HeaderEmphasis>{" "}
              <span>
                <Text
                  id="ingredients.evidence.header"
                  defaultMessage="or Bust"
                />
              </span>
            </Header>
            <Blurb>
              <Text
                id="ingredients.evidence.blurb"
                defaultMessage={`We use nutrients backed by a significant body of research that is
                  growing every day. Of the thousands of unaffiliated studies our
                  in-house scientists sorted through to guide our choices, the
                  titles below are some relevant MVPs.`}
              />
            </Blurb>
            <Articles>
              {studyData.map((study, i) => {
                return (
                  <Article
                    className="col-12"
                    ref={f => f}
                    key={`evidence-${i}`}
                  >
                    <ArticleContainer>
                      <StudyIconWrapper>
                        <Icons.Study />
                      </StudyIconWrapper>
                      <ArticleRow>
                        <div className="col-12 col-md-9 pl-0">
                          <StudyName>{study.name}</StudyName>
                        </div>
                        <div className="col-12 col-md-3 pl-0">
                          <StudyInstitution>
                            {study.institution}
                          </StudyInstitution>
                        </div>
                      </ArticleRow>
                    </ArticleContainer>
                  </Article>
                );
              })}
            </Articles>
          </div>
        </div>
      </EvidenceContainer>
    );
  }
}
